<template>
  <div
    class="experience-form pa-5"
    v-show="(paramID && objItem.title) || (obj && !paramID)"
  >
    <main-section
      v-show="step == 1"
      key="1"
      @nextHandler="stepOneHandler"
      @dayValue="dayValueHandler"
      :objItem="objItem"
    ></main-section>
    <steps-section
      v-show="step == 2"
      key="2"
      @backHandler="step = 1"
      @nextHandler="stepTwoHandler"
      :objItem="objItem"
      :dayValue="dayValue"
    ></steps-section>
    <price-section
      v-show="step == 3"
      key="3"
      @backHandler="step = 2"
      @nextHandler="submitHandler"
      :objItem="objItem"
    ></price-section>
  </div>
</template>

<script>
import MainSection from "./components/Main";
import StepsSection from "./components/Steps";
import PriceSection from "./components/TotalPrice";
export default {
  components: {
    MainSection,
    StepsSection,
    PriceSection
  },
  data: () => ({
    step: 0,
    obj: { schedules: [], media: [] },
    objItem: { schedules: [] },
    dayValue: {},
    coverImage: {},
    newCoverImage: "",
    images: [],
    request: ""
  }),
  watch: {
    $route: {
      async handler() {
        this.step = 1;
        await this.fetchItemById();
      }
    }
  },
  methods: {
    stepOneHandler(val) {
      this.step = 2;
      this.obj = { ...val.obj };
      this.images = val.images;
      this.objItem.duration = val.duration;
      this.coverImage = val.coverImage;
      this.newCoverImage = val.obj.newCoverImage;
    },
    stepTwoHandler(val) {
      this.obj = { ...this.obj, ...val };
      this.objItem = this.obj;
      this.step = 3;
    },
    async submitHandler(val) {
      this.obj.jedoCommision = +val;
      if (!this.paramID) {
        if (this.request) {
          this.obj.request = this.request;
        }
        let resCreate = await this.$axios.post(`custom-activity`, this.obj);

        if (resCreate.data) {
          // send cover image
          let formDataMedia = new FormData();
          formDataMedia.append("activityId", resCreate.data.data._id);
          formDataMedia.append("coverImage", this.coverImage);
          // request to upload media
          await this.$axios.post(`custom-activity/media/cover`, formDataMedia, {
            "content-type": "multipart/form-data"
          });

          if (this.images.length > 0) {
            const callback_Original = () => {
              return new Promise((resolve) => {
                this.images.forEach(async (img, i) => {
                  let formDataMedia = new FormData();
                  formDataMedia.append("activityId", resCreate.data.data._id);
                  formDataMedia.append("media", img.file);
                  // request to upload media

                  await this.$axios.post(
                    `custom-activity/media/images`,
                    formDataMedia,
                    {
                      "content-type": "multipart/form-data"
                    }
                  );

                  if (this.images.length - 1 == i) {
                    resolve();
                  }
                });
              });
            };
            callback_Original().then(async () => {
              this.$store.dispatch("showSnack", {
                text: "Custom experience added succesfully",
                color: `success`
              });
              this.$router.push("/custom-experiences");
            });
          } else {
            this.$router.push("/custom-experiences");
          }
        }
      } else {
        this.obj.images = this.images;
        if (this.newCoverImage) {
          // send cover image

          let formDataMedia = new FormData();
          formDataMedia.append("activityId", this.paramID);
          formDataMedia.append("coverImage", this.coverImage);
          // request to upload media

          await this.$axios.post(`custom-activity/media/cover`, formDataMedia, {
            "content-type": "multipart/form-data"
          });
        }

        const callback_Original = () => {
          return new Promise((resolve) => {
            this.images.forEach(async (img, i) => {
              if (!img._id) {
                let formDataMedia = new FormData();
                formDataMedia.append("activityId", this.paramID);
                formDataMedia.append("media", img.file);
                // request to upload media

                let res = await this.$axios.post(
                  `custom-activity/media/images`,
                  formDataMedia,
                  {
                    "content-type": "multipart/form-data"
                  }
                );

                if (res.data.data) {
                  this.obj = res.data.data;
                }
              }
              if (this.images.length - 1 == i) {
                resolve();
              }
            });
          });
        };
        callback_Original().then(async () => {
          // request to send infos to activity
          let resPut = await this.$axios.put(
            `custom-activity/${this.paramID}`,
            this.obj
          );
          if (resPut.data) {
            this.$store.dispatch("showSnack", {
              text: "edited succesfully",
              color: `success`
            });
            this.$router.push("/custom-experiences");
          }
        });
      }
    },
    dayValueHandler(val) {
      this.dayValue = [];
      for (let index = 0; index < val; index++) {
        this.dayValue.push({});
      }
    },
    async fetchItemById() {
      this.paramID = this.$route.params.id;
      if (this.paramID) {
        let { data } = await this.$axios.get(
          `/custom-activity/${this.paramID}`
        );

        if (data) {
          this.objItem = data.data;
        } else {
          this.objItem = {};
        }
      }
    }
  },
  async created() {
    this.step = 1;
    if (this.$route.query.requestID) {
      this.request = this.$route.query.requestID;
    }
    await this.fetchItemById();
  }
};
</script>

<style lang="scss">
.experience-form {
  margin: auto;
  height: 100vh;
}
</style>
