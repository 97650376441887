<template>
  <v-row>
    <v-col cols="12" class="mb-0 pt-0">
      <span class="font-26 font-500 d-block mb-5">Images & Media</span>
      <v-row>
        <v-col
          cols="6"
          md="3"
          v-for="(image, i) in images"
          :key="i"
        >
          <custom-image @delete="$emit('delete', i)" :image="image.url || image.mediaId.url"></custom-image>
        </v-col>
      </v-row>
    </v-col>

    <!-- upload new image -->
    <v-col cols="12">
      <div class="upload-item px-6 py-3">
        <div>
          <v-icon color="grayicon">mdi-folder-multiple-image</v-icon>
          <span class="font-14 black--text d-inline-block ml-2"
            >Upload Image</span
          >
        </div>
        <v-file-input
          prepend-icon=""
          @change="$emit('handleUpload', $event)"
          height="50"
          hide-details="true"
          accept="image/*"
          multiple
        ></v-file-input>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CustomImage from './CustomImage.vue';
export default {
  props:{
    images:{
      type: Array,
      default: () => []
    }
  },
  components: { CustomImage },}
</script>

<style></style>
