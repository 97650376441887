<template>
  <div>
    <div class="mt-10">
      <div class="d-flex align-center justify-space-between">
        <span class="font-20 black--text font-700">{{ objItem.name }}</span>
        <span class="font-20 black--text font-700">{{ totalDays }} days</span>
      </div>

      <div class="b-bottom my-2"></div>

      <div class="d-flex align-center justify-space-between">
        <span class="font-20 black--text font-700">Asking price</span>
        <span class="font-20 black--text font-700">$ {{ totalPrice }} </span>
      </div>

      <div class="d-flex justify-end align-center mt-3">
        <label class="grey--text font-14 font-500 mr-3">Jedo comission</label>
        <base-input v-model="obj.jedoCommision" placeholder="Add number" :rules="[]">
        </base-input>
      </div>

      <div class="mt-14">
        <buttons-experience
          @cancel="$emit('backHandler')"
          @next="nextHandler"
        ></buttons-experience>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonsExperience from '@/components/Buttons/ButtonsExperience.vue';

export default {
  props: {
    objItem: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ButtonsExperience,
  },
  data: () => ({
    obj: {},
  }),
  computed: {
    totalDays() {
      return this.objItem.schedules.length;
    },
    totalPrice() {
      let price = 0;
      this.objItem.schedules.forEach((day) => {
        day.day.schedule.forEach((sch) => {
          price += +sch.price;
        });
      });

      return price;
    },
  },
  methods: {
    async nextHandler() {
      this.$emit("nextHandler", this.obj.jedoCommision);
    },
  },
  created(){
    if(this.$route.params.id){
      this.obj.jedoCommision = this.objItem.jedoCommision
    }
  }
};
</script>

<style></style>
