<template>
  <div>
    <v-form v-model="valid" @submit.prevent="submitForm" ref="form">
      <!-- upload image -->
      <div class="mt-10">
        <v-row>
          <v-col cols="12" class="py-1 pb-2">
            <div class="upload-image">
              <img
                v-if="coverImage"
                :src="newCoverImage ? newCoverImage : coverImage.url"
                alt=""
              />
              <div class="upload-btn cursor-pointer">
                <v-icon dark color="grey">mdi-upload</v-icon>
                <span class="font-15 grey--text">Upload Image</span>
                <v-file-input
                  @change="handleCoverImage"
                  accept="image/*"
                  label="File input"
                  class="file-upload"
                  hide-details="true"
                ></v-file-input>
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- users -->
        <span class="grey--text font-12 font-500 d-block mb-6 mt-4">Users</span>
        <v-row>
          <v-col cols="12" md="12" class="py-1">
            <v-autocomplete
              v-if="users"
              v-model="selectedUsers"
              :items="displayedUsers"
              outlined
              dense
              chips
              :filter="() => true"
              itemValue=""
              small-chips
              label="Search for user"
              multiple
              menu-props="auto"
              :search-input.sync="userSearchQuery"
              @update:search-input="searchUsers"
              @input="userSearchQuery = undefined"
              :rules="[(v) => !!v || v >= 0 || '']"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex mt-3">
                  <div
                    class="mr-2 d-block rounded-circle"
                    style="width: 24px; height: 24px; overflow: hidden"
                  >
                    <img
                      class=""
                      v-if="item.google && item.google.picture"
                      :src="item.google.picture"
                      alt=""
                      width="24"
                      height="24"
                    />
                  </div>
                  <p class="d-block">
                    {{ item.name }}
                  </p>
                </div>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip
                  :key="index"
                  close
                  @click:close="selectedUsers.splice(index, 1)"
                  class="ma-2"
                >
                  <div class="d-flex mt-3">
                    <div
                      class="mr-2 d-block rounded-circle"
                      style="width: 24px; height: 24px; overflow: hidden"
                      v-if="item.google && item.google.picture"
                    >
                      <img
                        class=""
                        :src="item.google.picture"
                        alt=""
                        width="24"
                        height="24"
                      />
                    </div>
                    <p class="d-block">
                      {{ item.name }}
                    </p>
                  </div>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <!-- **** Main Info **** -->
          <v-col cols="12" class="mb-0 pt-0">
            <span class="font-26 font-500 d-block mb-5">Main Info</span>
            <v-row class="px-8">
              <!-- Experience  Name -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500"
                  >Experience Name</label
                >
                <base-input v-model="obj.name" placeholder="Experience  Name">
                </base-input>
              </v-col>
              <!-- Experience  price -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500"
                  >Experience Price</label
                >
                <base-input
                  v-model="obj.price"
                  type="number"
                  placeholder="Experience  Price"
                >
                </base-input>
              </v-col>

              <!-- Country -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500">Country</label>
                <base-select
                  v-model="obj.country"
                  :items="countries"
                  placeholder="Country"
                  itemText="name"
                  itemValue="_id"
                ></base-select>
              </v-col>

              <!-- LOCATION -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500"> City name </label>
                <GmapAutocomplete
                  class="place-input"
                  ref="gMapAutocomplete"
                  :select-first-on-enter="true"
                  placeholder="City name"
                  @place_changed="setPlace"
                />
              </v-col>
              <v-col cols="12" md="12" class="py-1">
                <GmapMap
                  :clickable="true"
                  ref="maps"
                  :center="center"
                  :zoom="zoom"
                  map-type-id="roadmap"
                  style="width: 100%; height: 500px"
                  @click="addMarker"
                >
                  <GmapMarker
                    v-if="this.marker"
                    :position="{ lat: this.marker.lat, lng: this.marker.lng }"
                  />
                </GmapMap>
              </v-col>
              <!-- Title -->
              <v-col cols="12">
                <label class="grey--text font-12 font-500">Title</label>
                <base-textarea
                  :rules="[(v) => !!v || 'this field is require']"
                  v-model="obj.title"
                  placeholder="What is this event about? Tell us more juicy details."
                ></base-textarea>
              </v-col>

              <!-- description -->
              <v-col cols="12">
                <label class="grey--text font-12 font-500">DESCRIPTION</label>
                <base-textarea
                  :rules="[(v) => !!v || 'this field is require']"
                  v-model="obj.description"
                  placeholder="What is this event about? Tell us more juicy details."
                ></base-textarea>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <div class="b-bottom my-1"></div>
          </v-col>

          <!-- **** Images & Media **** -->
          <main-images
            :images="images"
            @handleUpload="handleUpload"
            @delete="handleDelete"
          ></main-images>

          <v-col cols="12">
            <span class="font-26 font-500 d-block">Timing & Availability</span>
          </v-col>

          <!-- **** Stats **** -->
          <v-col cols="12" class="mb-0 pt-0 pb-0">
            <span class="font-26 font-500 d-block">Stats</span>
          </v-col>
          <v-col cols="12" class="px-6">
            <v-row
              class="px-3 whitebg"
              :class="stats.length == 0 ? '' : 'py-2'"
            >
              <v-col
                cols="12"
                md="6"
                class="py-2"
                v-for="(stat, i) in stats"
                :key="i"
              >
                <v-checkbox
                  color="darkblue"
                  hide-details="true"
                  v-model="stat.checked"
                  :label="stat.name"
                  @change="stateChange(stat)"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" v-for="(stat, i) in statInputs" :key="i">
                <span class="grey--text font-12 font-500">{{ stat.name }}</span>
                <base-input
                  v-if="stat.checked && stat.unit"
                  v-model="stat.value"
                  :placeholder="stat.name"
                ></base-input>
                <base-select
                  v-if="stat.checked && !stat.unit"
                  :items="stat.selections"
                  itemText="value"
                  itemValue="_id"
                  v-model="stat.value"
                  :placeholder="stat.name"
                ></base-select>
              </v-col>
            </v-row>
          </v-col>
          <!-- dates -->
          <div class="mt-6">
            <base-date
              :key="dateKey"
              v-model="obj.date"
              @valueHandler="dataHandler"
            ></base-date>
          </div>

          <!-- Duration -->
          <v-col cols="12" class="my-0 py-0">
            <span class="grey--text font-12 font-500 d-block">Durations</span>
          </v-col>
          <v-col cols="6" class="pt-1">
            <base-input
              placeholder="Durations"
              type="number"
              v-model="obj.duration"
              :rules="[(v) => !!v || v >= 0 || '']"
            ></base-input>
          </v-col>
          <v-col cols="6" class="py-1">
            <base-select
              :items="dayOrHour"
              v-model="obj.dayOrHour"
              itemText="name"
              itemValue="_id"
              placeholder="Hour, day"
              :rules="[(v) => !!v || v >= 0 || '']"
            ></base-select>
          </v-col>

          <!-- start TIME -->
          <v-col cols="6" md="6">
            <span class="grey--text font-12 font-500 d-block">Start Time</span>
            <div class="d-flex align-center justify-space-between">
              <input
                class="ml-2 font-20 align-center"
                style="
                  border: 0.5px solid rgb(219, 216, 216);
                  border-radius: 2px;
                "
                type="time"
                v-model="obj.startTime"
              />
            </div>
          </v-col>

          <!-- end TIME -->
          <v-col cols="6" md="6">
            <span class="grey--text font-12 font-500 d-block">End Time</span>
            <div class="d-flex align-center justify-space-between">
              <input
                class="ml-2 font-20 align-center"
                style="
                  border: 0.5px solid rgb(219, 216, 216);
                  border-radius: 2px;
                "
                type="time"
                v-model="obj.endTime"
              />
            </div>
          </v-col>

          <v-col cols="12" class="mt-14">
            <!-- buttons -->
            <buttons-experience
              @cancel="$emit('backHandler')"
              @next="handleNext"
            ></buttons-experience>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
import MainImages from "./main/Images.vue";
export default {
  props: {
    objItem: {
      type: Object,
      default: () => {}
    },
    step: {
      type: Number,
      default: 0
    }
  },
  components: {
    ButtonsExperience,
    MainImages
  },
  computed: {
    displayedUsers() {
      return [...this.users, ...this.selectedUsers].filter(
        (value, index, self) =>
          self.findIndex((item) => item._id === value._id) === index
      );
    }
  },
  data: () => ({
    marker: undefined,
    center: { lng: 35.8984486, lat: 33.8786494 },
    zoom: 9.5,
    name: "",
    newCoverImage: "",
    countries: [{ name: "Lebanon", _id: "LB" }],
    valid: false,
    datetime: new Date(),
    coverImage: {},
    images: [],
    obj: {
      country: "LB",
      location: {}
    },
    users: [],
    dayOrHour: [
      { name: "Day", _id: 0 },
      { name: "Hour", _id: 1 }
    ],

    maxHour: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 24 || ""
    ],

    maxMin: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 60 || ""
    ],
    files: [],
    filesUploaded: false,
    paramID: "",

    imagePreview: false,
    imagePreviewUrl: "",

    isEdit: false,
    keyChanged: false,
    keyMarkChange: false,

    selectedUsers: [],
    dateKey: false,

    stats: [],
    statInputs: [],
    userSearchQuery: undefined
  }),

  methods: {
    handleCoverImage(file) {
      this.newCoverImage = URL.createObjectURL(file);
      this.coverImage = file;
      if (this.paramID) {
        this.obj.newCoverImage = URL.createObjectURL(file);
      }
    },
    handleUpload(files) {
      files.forEach((file) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
          this.$store.dispatch("showSnack", {
            text: "File size too big! bigger than 1MB, select a smaller one",
            color: "error"
          });
          return;
        }
        this.images.push({ file: file, url: URL.createObjectURL(file) });
      });
    },

    handleDelete(i) {
      this.images = [...this.images.filter((_, idx) => idx != i)];
    },
    dataHandler(event) {
      this.obj.date = new Date(event).toISOString();
      this.dateKey = !this.dateKey;
    },
    async fetchItemByID() {
      this.obj = { ...this.objItem };
      this.images = this.objItem.images.map((img) => {
        return {
          url: img.mediaId ? img.mediaId.url : "",
          _id: img._id,
          mediaId: img.mediaId ? img.mediaId._id : ""
        };
      });

      // set location
      this.marker = {
        lng: Number(this.obj.location.long),
        lat: Number(this.obj.location.lat)
      };
      this.center = this.marker;
      this.zoom = 15;
      this.$refs.gMapAutocomplete.$el.value = this.obj.location.address;

      // set time
      if (this.obj.startTime) {
        this.obj.startTime = this.$convertTo24Hour(this.obj.startTime);
      }

      if (this.obj.endTime) {
        this.obj.endTime = this.$convertTo24Hour(this.obj.endTime);
      }
      this.coverImage = this.obj.coverImage;
      this.images = this.obj.images;
      this.selectedUsers = [...this.obj.users];

      // set stats
      this.stats = this.obj.stats.map((stat) => {
        if (stat.unit) {
          return {
            statType: stat.statType,
            value: stat.value,
            unit: stat.unit,
            selections: [],
            name: stat.statType.name,
            checked: true,
            inputType: "1"
          };
        } else {
          return {
            statType: stat.statType,
            value: stat.value,
            unit: null,
            selections: stat.statType.selections,
            name: stat.statType.name,
            checked: true,
            inputType: "0"
          };
        }
      });
      this.stats.forEach((stat) => {
        this.stateChange(stat);
      });
    },
    handleNext() {
      this.obj.users = this.selectedUsers.map((user) => user._id);
      this.$refs.form.validate();
      if (!this.valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth"
            });
        }, 100);
        return;
      }
      if (!this.obj.location.long || !this.obj.location.lat) {
        this.$store.dispatch("showSnack", {
          text: "Location Marker is Missing",
          color: "error"
        });
        return;
      }
      if (!this.coverImage) {
        this.$store.dispatch("showSnack", {
          text: "cover image is required",
          color: `error`
        });
        return;
      }

      if (this.images.length == 0) {
        this.$store.dispatch("showSnack", {
          text: "one image require at least",
          color: `error`
        });
        return;
      }

      this.obj.startTime = this.$convertTo12Hour(this.obj.startTime);
      this.obj.endTime = this.$convertTo12Hour(this.obj.endTime);
      // handle stats
      if (this.stats.length > 0) {
        // get only checked stats
        this.obj.stats = [...this.statInputs.filter((stat) => stat.checked)];
        this.obj.stats = [
          ...this.statInputs.map((stat) => {
            if (stat.inputType == "1") {
              return {
                statType: this.paramID
                  ? stat.statType._id
                    ? stat.statType._id
                    : stat.statType
                  : stat._id,
                value: stat.value,
                unit: stat.unit
              };
            } else {
              return {
                statType: this.paramID
                  ? stat.statType._id
                    ? stat.statType._id
                    : stat.statType
                  : stat._id,
                value: stat.value,
                unit: null
              };
            }
          })
        ];
      }
      if (this.obj.dayOrHour._id) {
        this.obj.dayOrHour = this.obj.dayOrHour._id;
      }
      this.$emit("nextHandler", {
        obj: this.obj,
        images: this.images,
        coverImage: this.coverImage
      });
      this.$emit("dayValue", this.obj.dayOrHour == 1 ? 1 : this.obj.duration);
    },
    setPlace(event) {
      if (event.geometry && event.geometry.location) {
        this.marker = {
          lat: event.geometry.location.lat(),
          lng: event.geometry.location.lng()
        };
        this.center = event.geometry.location;
        this.zoom = 15;
        this.obj.location.long = this.marker.lng;
        this.obj.location.lat = this.marker.lat;
        this.obj.location.address = this.$refs.gMapAutocomplete.$el.value;
      }
    },
    addMarker(event) {
      this.marker = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      this.obj.location.long = this.marker.lng;
      this.obj.location.lat = this.marker.lat;
      this.obj.location.address = this.$refs.gMapAutocomplete.$el.value;
    },
    stateChange(stat) {
      if (stat.checked) {
        let foundStat = this.statInputs.find(
          (statInput) => statInput.name == stat.name
        );

        if (!foundStat) {
          this.statInputs.push(stat);
        }
      } else {
        this.statInputs = this.statInputs.filter(
          (statInput) => statInput.name != stat.name
        );
      }
    },
    async searchUsers(searchWord) {
      let { data } = await this.$axios.get(
        `users/all/new?searchWord=${searchWord || ""}&pageSize=20`
      );
      if (data) {
        this.users = data.data;
      }
    },
    async fetchStats() {
      let { data } = await this.$axios.get(`activityStats`);
      if (data) {
        this.stats = data.stats;
        this.stats = this.paramID
          ? [
              ...data.stats.map((stat) => {
                let objStat = this.obj.stats.filter((s) => {
                  if (s.statType) {
                    return s.statType._id == stat._id;
                  } else {
                    return s._id == stat._id;
                  }
                });
                if (objStat.length > 0) {
                  return {
                    ...stat,
                    value: objStat[0].value,
                    checked: true,
                    statType: stat._id
                  };
                } else {
                  return { ...stat, checked: false, statType: stat._id };
                }
              })
            ]
          : [
              ...data.stats.map((stat) => {
                return { ...stat, checked: false };
              })
            ];
      }
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    if (this.$route.query.date) {
      this.obj.date = this.$route.query.date;
    }
    await this.searchUsers();
    if (this.paramID) {
      await this.fetchItemByID();
    } else {
      if (this.$route.query.userID) {
        this.selectedUsers[0] = {
          name: this.$route.query.userName,
          _id: this.$route.query.userID
        };
      }
    }
    await this.fetchStats();
  }
};
</script>

<style scoped>
.place-input {
  height: 36px;
  line-height: 35px;
  border: 1px solid #e2e5ed;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
